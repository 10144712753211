var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Allocated Applications "),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","href":"https://docs.synpse.net/synpse-core/applications/deploy","target":"_blank","rel":"noopener"}},tooltip),[_c('v-icon',{attrs:{"color":"grey","small":""}},[_vm._v("help")])],1)]}}])},[_c('span',[_vm._v("If device matches application scheduling criteria - allocation will be created")])])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.device.applications,"sort-by":"key","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
              name: 'applicationDetails',
              params: {
                project: item.projectId,
                namespace: item.namespaceId,
                application: item.id,
              },
            }}},[_vm._v(_vm._s(item.name))])]}},{key:"item.containers",fn:function(ref){
            var item = ref.item;
return [_vm._l((item.spec.containers),function(container,i){return [_vm._v(" "+_vm._s(container.name)+" "),_c('span',{key:i},[_vm._l((container.ports),function(port,i){return _c('span',{key:i},[_c('a',{attrs:{"href":_vm.containerUrl(port),"target":"_blank","rel":"noopener"}},[_vm._v(_vm._s(port))])])}),_c('br')],2)]})]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"mr-2"},[(
                item.deploymentStatus.available == item.deploymentStatus.total
              )?_c('v-icon',{attrs:{"color":"green accent-3"}},[_vm._v("check_circle_outline")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("error_outline")])],1)]}},{key:"item.allocation",fn:function(ref){
              var item = ref.item;
return [_c('router-link',{attrs:{"to":{
              name: 'applicationAllocations',
              params: {
                project: item.projectId,
                namespace: item.namespaceId,
                application: item.id,
              },
            }}},[_vm._v(" "+_vm._s(item.deploymentStatus.available)+"/"+_vm._s(item.deploymentStatus.total)+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                name: 'applicationDetails',
                params: {
                  project: item.projectId,
                  namespace: item.namespaceId,
                  application: item.id,
                },
              }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])],1)]}},{key:"no-data",fn:function(){return [_vm._v("No Applications Running On This Device")]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }