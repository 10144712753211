<template>
  <div>
    <v-card outlined>
      <v-card-title>Allocated Applications

        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              v-on="tooltip"
              href="https://docs.synpse.net/synpse-core/applications/deploy"
              target="_blank"
              rel="noopener"
            >
              <v-icon color="grey" small>help</v-icon>
            </v-btn>
          </template>
          <span>If device matches application scheduling criteria - allocation will be created</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="device.applications"
          sort-by="key"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:[`item.name`]="{ item }">
            <router-link
              :to="{
                name: 'applicationDetails',
                params: {
                  project: item.projectId,
                  namespace: item.namespaceId,
                  application: item.id,
                },
              }"
              >{{ item.name }}</router-link
            >
          </template>
          <template v-slot:[`item.containers`]="{ item }">
            <template v-for="(container, i) in item.spec.containers">
              {{ container.name }}
              <span :key="i">
                <span v-for="(port, i) in container.ports" :key="i">
                  <a :href="containerUrl(port)" target="_blank" rel="noopener">{{ port }}</a>
                </span>
                <br
              /></span>
            </template>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span class="mr-2">
              <v-icon
                v-if="
                  item.deploymentStatus.available == item.deploymentStatus.total
                "
                color="green accent-3"
                >check_circle_outline</v-icon
              >
              <v-icon v-else color="error">error_outline</v-icon>
            </span>
          </template>

          <template v-slot:[`item.allocation`]="{ item }">
            <router-link
              :to="{
                name: 'applicationAllocations',
                params: {
                  project: item.projectId,
                  namespace: item.namespaceId,
                  application: item.id,
                },
              }"
            >
              {{ item.deploymentStatus.available }}/{{
                item.deploymentStatus.total
              }}
            </router-link>
          </template>

          <template v-slot:item.actions="{ item }">
            <router-link
                :to="{
                  name: 'applicationDetails',
                  params: {
                    project: item.projectId,
                    namespace: item.namespaceId,
                    application: item.id,
                  },
                }"
                >
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
              </router-link>
            
            <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
          </template>
          <template v-slot:no-data
            >No Applications Running On This Device</template
          >
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    device: Object
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: 'Containers', value: 'containers' },
        { text: 'Allocation', value: 'allocation' },
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ]
    }
  },
  computed: {
    
  },
  methods: {
    containerUrl(port) {
      
      if (!port.includes(":")) {
        if (port === "443" || port === "8443") {
          return `https://${this.device.info.ipAddress}:${port}`
        }
        return `http://${this.device.info.ipAddress}:${port}` 
      }
      let hostPort = port.split(":")[0]

      if (hostPort === "443" || hostPort === "8443") {
        return `https://${this.device.info.ipAddress}:${hostPort}`
      }
      return `http://${this.device.info.ipAddress}:${hostPort}` 

      // Splitting and getting the first entry
      // if (container.por)
    }
  }
}
</script>

<style>
</style>